import React, { useState, useEffect } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { onCheckStatus, onDeleteVideoSet, openVideoSet } from '../../actions/contentAction'
import { FaTrashAlt } from "react-icons/fa"
import SweetAlert from "react-bootstrap-sweetalert";


let interval = false
const VideoSetCard = ({ curElem, index, img, fetchContentData }) => {
console.log("222222curelm11111111111",curElem)
    const dispatch = useDispatch()
    const history = useHistory()
    const [status, setStatus] = useState(curElem.status)
    const [state, setState] = useState({
        button: "Delete",
        show: false,
    })
    const handleOpenCard = (data) => {
        if (+data.status === 4 || +data.status === 3) {
        } else {
            dispatch(openVideoSet(data))
            history.push(`/video-list`)
        }
    }

    const checkStatus = () => {
        let data = {
            id: curElem.id,
            type: "videoSet"
        }
        dispatch(onCheckStatus(data, interval, fetchContentData, setStatus))
    }

    const handleDelete = (e) => {
        e.stopPropagation()
        setState({
            ...state,
            show: true
        })
    }

    const onConfirm = () => {
        setState({
            ...state,
            button: "Deleting..."
        })
        dispatch(onDeleteVideoSet(curElem.id, fetchContentData, state, setState))

    }


    useEffect(() => {
        if (+status === 4) {
            console.log("0000000000000000")
            interval = setInterval(() => {
                checkStatus()
            }, 5000)
        }
        return () => clearInterval(interval)
    }, [])
console.log("1222status",status)
    return (
        <div
            className="col-xl-3 col-lg-4 col-sm-6"
            onClick={() => handleOpenCard(curElem)}
            key={index}
        >
            <div className={`contWrap-list-single`}>
                <div>
                    <div className="contWrap-img"><img src={img} alt="" /></div>
                    <div className="contWrap-txt pt-2">
                        <h5>{curElem.name}</h5>
                        <p>{curElem.dimension}</p>
                    </div>
                </div>
                <div className="progressWrap">
                    {
                        +status === 4 ?
                            <>
                                <p style={{ fontWeight: "bold", marginBottom: "5px" }}>Generating Script</p>
                                <ProgressBar variant="warning" animated now={100} />
                            </>
                            : +status === 3 ?
                                <p style={{ fontWeight: "bold", marginBottom: "15px" }}>Script Generation Failed</p>
                                : +status === 1 ?
                                    <p style={{ fontWeight: "bold", marginBottom: "15px" }}>Script Generated</p>
                                    : ""}
                </div>
                <div className='video-set-delete-icon'>
                    <FaTrashAlt onClick={handleDelete} />
                </div>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={state.button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="secondary"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => setState({
                    ...state,
                    show: false
                })}
                focusCancelBtn
                show={state.show}
            >
              
            </SweetAlert>


        </div>
    )
}

export default VideoSetCard